const decreaseBtn = document.getElementById("decrease");
const increaseBtn = document.getElementById("increase");
const numberOfBoxesInput = document.getElementById("number-of-boxes");
const pricePerBox = document.getElementById("price-per-box");
const totalPriceDisplay = document.getElementById("total-price");
let password = document.getElementById("password");
let confirmPassword = document.getElementById("confirm-password");
const orderFormSchool = document.getElementById("order-form-school");
let pricePerBoxNumeric;
const radiobuttonEInvoice = document.querySelector('input[value="E-faktura"]');
const radiobuttonPdfInvoice = document.querySelector('input[value="PDF"]');
const successMessage = document.getElementById("signup-message");

const zero = 0;
const one = 1;
const two = 2;

if (pricePerBox) {
  const priceText = pricePerBox.textContent.trim();
  pricePerBoxNumeric = priceText.replace(/\D/g, "");
  document.getElementById("price-per-box-input").value = pricePerBoxNumeric;
}

function updateTotalPrice() {
  const numberOfBoxes = parseInt(numberOfBoxesInput.value, 10) || zero;
  numberOfBoxesInput.value = numberOfBoxes;
  totalPriceDisplay.innerHTML = numberOfBoxes * pricePerBoxNumeric + " kr";
  document.getElementById("total-price-input").value = numberOfBoxes * pricePerBoxNumeric;
}

function decreaseValue() {
  let numberOfBoxes = parseInt(numberOfBoxesInput.value, 10) || zero;
  numberOfBoxes = Math.max(numberOfBoxes - one, one);
  numberOfBoxesInput.value = numberOfBoxes;
  updateTotalPrice();
}

function increaseValue() {
  let numberOfBoxes = parseInt(numberOfBoxesInput.value, 10) || zero;
  numberOfBoxes++;
  numberOfBoxesInput.value = numberOfBoxes;
  updateTotalPrice();
}

if (numberOfBoxesInput) {
  numberOfBoxesInput.addEventListener("input", updateTotalPrice);
}
if (decreaseBtn) {
  decreaseBtn.addEventListener("click", decreaseValue);
}
if (increaseBtn) {
  increaseBtn.addEventListener("click", increaseValue);
}

const validatePasswords = () => {
  if (password.value && confirmPassword.value) {
    confirmPassword.setCustomValidity(password.value !== confirmPassword.value ? "Ogiltigt fält." : "");
  }
};

if (password) {
  password.addEventListener("focusout", validatePasswords);
}
if (confirmPassword) {
  confirmPassword.addEventListener("focusout", validatePasswords);
}

function handleTypeOfInvoice() {
  let eInvoiceWrapper = document.getElementById("e-invoice-wrapper");
  let glnNumber = document.getElementById("gln-number");
  let referenceCode = document.getElementById("reference-code");
  if (radiobuttonEInvoice.checked) {
    eInvoiceWrapper.style.display = "flex";
    glnNumber.disabled = false;
    referenceCode.disabled = false;
  } else if (radiobuttonPdfInvoice.checked) {
    eInvoiceWrapper.style.display = "none";
    glnNumber.disabled = true;
    referenceCode.disabled = true;
  }
}

if (radiobuttonEInvoice) {
  radiobuttonEInvoice.addEventListener("change", handleTypeOfInvoice);
}
if (radiobuttonPdfInvoice) {
  radiobuttonPdfInvoice.addEventListener("change", handleTypeOfInvoice);
}

function handleOrder() {
  document.getElementById("order-form-school").addEventListener("submit", function (event) {
    var form = event.target;

    if (document.body.classList.contains("logged-in")) {
      if (!form.checkValidity()) {
        event.preventDefault();
      } else {
        window.signup.submit("#order-form-school");
      }
    } else {
      event.preventDefault();
      if (form.checkValidity()) {
        window.signup.submit("#order-form-school");
      }
    }
  });

  window.signup = { ...window.signup };
  window.signup.submit = (formSelector) => {
    var formElement = document.querySelector(formSelector);
    const action = document.getElementById("action-value").value;
    const name = document.getElementById("school-name").value;
    const email = document.getElementById("email").value;
    const password1 = document.getElementById("password").value;
    const password2 = document.getElementById("confirm-password").value;
    const municipality = encodeURIComponent(document.getElementById("municipality").value);

    const encodedAction = encodeURIComponent(action);
    const encodedName = encodeURIComponent(name);
    const encodedEmail = encodeURIComponent(email);
    const encodedPassword = encodeURIComponent(password1);
    const encodedPassword2 = encodeURIComponent(password2);
    const addingAnotherLogin = false;

    const body =
      "action=" +
      encodedAction +
      "&name=" +
      encodedName +
      "&email=" +
      encodedEmail +
      "&password=" +
      encodedPassword +
      "&password2=" +
      encodedPassword2 +
      "&municipality=" +
      municipality +
      "&adding_another_login=" +
      addingAnotherLogin;

    fetch(formElement.getAttribute("action"), {
      method: "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
      body: body,
    }).then((data) => {
      var message = "done";
      if (data.redirected && data.url) {
        var match = /\bmessage=([^&]*)/.exec(data.url);
        if (match && match.length === two) {
          message = match[one];
        }
      }
      if (message === "done") {
        formElement.submit();
      } else if (message === "sending-email-failed") {
        alert(
          "Registreringen lyckades! Vi har problem med mailutskick just nu, men du kan logga in med uppgifterna du angav."
        );
        formElement.submit();
      } else if (message === "signup-failed") {
        window.location.href = "/signup-failed";
      } else {
        alert("error: " + message);
      }
    });
  };
}

if (orderFormSchool) {
  handleOrder();
}

function onFocus() {
  if (!successMessage.classList.contains("hide")) {
    successMessage.classList.add("hide");
  }
}

if (successMessage) {
  const addMemberInputFields = document.querySelectorAll(".addMemberInput");
  addMemberInputFields.forEach((inputField) => {
    inputField.addEventListener("focus", onFocus);
  });
}
